<template>

    <transition name="grid-item"
                @before-enter="onBeforeEnter"
                @enter="onEnter"
                @after-enter="onAfterEnter"
                @enter-cancelled="onEnterCancelled"
                @before-leave="onBeforeLeave"
                @leave="onLeave"
                @after-leave="onAfterLeave"
                @leave-cancelled="onLeaveCancelled"
                mode="out-in">
        <li class="client-grid-item"
            :key="item.pagetitle">
            <div class="item-wrapper no-border no-bg">
                    <div class="inner"
                         :style="itemStyle + ' position:relative; perspective:1000px;'">
                        <img :src="item.clientLogoImage1x"
                             :alt="item.pagetitle"
                             :title="item.pagetitle"
                             ref="clientLogo" style="will-change: opacity, filter, transform;" />
                    </div>
            </div>
            <div class="vert-line"></div>
        </li>
    </transition>

</template>


<script>

import gsap from 'gsap';

export default {

    name: 'ClientGridItem',

    props: {
        item: {
            type: Object,
            default() { return {}; }
        },
        collection: {
            type: Array,
            default() { return []; }
        }
    },
    
    data() {
        return {
            componentName: this.$options.name + ' Component'
        };
    },

    computed: {

        itemStyle() {
            let _style = '';
            if (this.item.clientLogoMaxWidthGrid && (this.item.clientLogoMaxWidthGrid !== '')) {
                _style += 'max-width:' + this.item.clientLogoMaxWidthGrid + ';';
            }
            if (this.item.clientLogoMaxHeightGrid && (this.item.clientLogoMaxHeightGrid !== '')) {
                _style += 'max-height:' + this.item.clientLogoMaxHeightGrid + ';';
            }
            return _style;
        }

    },
    
    created() {
        
    },
    
    mounted() {

    },
    
    methods: {

        /**
         * @method onBeforeEnter
         * @description Called before element DOM insertion; Use to set `enter-from` element state.
         * @param {Object} el
         */
        onBeforeEnter(el) { // eslint-disable-line
            let _img = this.$refs.clientLogo;
            gsap.set(_img, {// eslint-disable-line
                opacity: 0,
                scale: 0.8,
                filter: 'blur(24px)',
                rotationY: -90
            });
        },

        /**
         * @method onEnter
         * @description Called one frame after element DOM insertion; Use to start "entering" animation.
         * @param {Object} el
         * @param {Function} done
         */
        onEnter(el, done) { // eslint-disable-line
            let _img = this.$refs.clientLogo;
            gsap.fromTo(_img, { // eslint-disable-line
                opacity: 0,
                scale: 0.8,
                filter: 'blur(24px)',
                rotationY: -90
            }, {
                duration: 1.0,
                opacity: 1,
                scale: 1,
                filter: 'blur(0px)',
                rotationY: 0,
                ease: 'power2.out',
                onComplete: done // Callback to indicate transition end; optional is used in tandem with CSS.
            });
        },

        /**
         * @method onAfterEnter
         * @description Called when `enter` transition complete.
         * @param {Object} el
         */
        onAfterEnter(el) { // eslint-disable-line
        },
        onEnterCancelled(el) { // eslint-disable-line
        },

        /**
         * @method onBeforeLeave
         * @description Called before `leave` hook; Should mainly use `leave` hook if possible.
         * @param {Object} el
         */
        onBeforeLeave(el) { // eslint-disable-line
        },

        /**
         * @method onLeave
         * @description Called when `leave` transition starts; Use to start "leaving" animation.
         * @param {Object} el
         * @param {Function} done
         */
        onLeave(el, done) { // eslint-disable-line
            let _img = this.$refs.clientLogo;
            gsap.fromTo(_img, { // eslint-disable-line
                opacity: 1,
                scale: 1,
                filter: 'blur(0px)',
                rotationY: 0
            }, {
                duration: 1,
                opacity: 0,
                scale: 0.8,
                filter: 'blur(24px)',
                rotationY: -90,
                ease: 'power2.out',
                onComplete: done // Callback to indicate transition end; optional is used in tandem with CSS.
            });
        },

        /**
         * @method onAfterLeave
         * @description Called when `leave` transition complete & element removed from DOM.
         * @param {Object} el
         */
        onAfterLeave(el) { // eslint-disable-line
        },

        /**
         * @method onLeaveCancelled
         * @description Only available to `v-show` transitions.
         * @param {Object} el
         */
        onLeaveCancelled(el) { // eslint-disable-line
        }

    }
    
};

</script>


<style lang="scss" scoped>

</style>


