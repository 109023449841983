<template>

    <ul id="neo-client-grid"
        class="our-clients--list no-bull"
        v-if="moduleReady && clientsSelections.length > 0">
            <client-grid-item v-for="(item, idx) in clientsSelections"
                              :item="item"
                              :key="'client_item_' + idx"></client-grid-item>
    </ul>

</template>


<script>

import ClientGridItem from './ClientGridItem';

export default {

    name: 'ClientGrid',

    components: {
        ClientGridItem
    },

    props: {
        msg: String
    },

    data() {
        return {
            componentName: this.$options.name + ' Component',
            moduleReady: false,
            clients: [],
            clientsSelections: [],
            clientsSelectorId: '#client-grid-data',
            defaultDisplayLimit: 4,
            clientsInterval: null,
            defaultClientsTimeout: 3000,
            lastIndex: 0
        };
    },

    computed: {

        displayLimit() {
            return (this.$root.limit && this.$root.limit !== '') ? Number(this.$root.limit) : this.defaultDisplayLimit;
        },

        clientsTimeout() {
            return (this.$root.timeout && this.$root.timeout !== '') ? Number(this.$root.timeout) : this.defaultClientsTimeout;
        }

    },

    created() {
        this.getClientsData();
        this.setClientsSelections();
    },

    mounted() {
        this.moduleReady = true;
        this.clientsInterval = setInterval(this.updateClientsArray, this.clientsTimeout);
    },

    methods: {

        /**
         * @method getClientsData
         * @description Get the clients JSON data from DOM script element.
         */
        getClientsData() {
            this.clients = JSON.parse(document.querySelector(this.clientsSelectorId).textContent) || [];
        },

        /**
         * @method setClientsSelections
         * @description Apply limit to clients selection array, if one exists.
         */
        setClientsSelections() {
            this.clientsSelections = this.displayLimit ? this.clients.slice(0, this.displayLimit) : this.clients;
        },

        /**
         * @method getRandomFromClientsData
         * @description Get a random client object that is not already in the clients selections array.
         * @return {*|{}}
         */
        getRandomFromClientsData() {
            let _randItem = {};
            let _alreadyExists = undefined;

            while(true) { // eslint-disable-line
                let _rand = Math.floor(Math.random() * this.clients.length);

                // get random item from entire collection
                _randItem = this.clients[_rand];

                // check if item exists in current selections
                _alreadyExists = this.clientsSelections.find((obj) => {
                    return obj.pagetitle === _randItem.pagetitle;
                });

                // random selection not already existing in current clients selection array; prevent dupe display
                if (!_alreadyExists) {
                    break;
                }
            }

            // preload the sombish; prevents blip on fade-in w/uncached bishes
            let _img = new Image();
            _img.src = _randItem.clientLogoImage1x;

            return _randItem;
        },

        /**
         * @method updateClientsArray
         * @description Update the clients selections array at a random index.
         */
        updateClientsArray() {
            // get rando client item from entire collection
            let _rando = this.getRandomFromClientsData();

            // get random item to update
            let _randoItem = this.clientsSelections[this.randomIndex(this.clientsSelections, this.lastIndex)];

            // get the index of the selected random item
            let _indexToUpdate = this.clientsSelections.findIndex(item => item === _randoItem);

            // set last index to use for dupe check on next cycle
            this.lastIndex = _indexToUpdate;

            // update the current selections array with new random selection
            this.clientsSelections.splice(_indexToUpdate, 1, _rando);
        },

        /**
         * @method randomIndex
         * @description Get random index from array which excludes a specific index.
         * @param {Array} arr
         * @param {Number} excludeIndex
         * @return {String}
         */
        randomIndex(arr, excludeIndex) {
            let indexes = Object.keys(arr); // get a list of indexes
            indexes.splice(excludeIndex, 1); // remove the unwanted index
            return indexes[Math.floor(Math.random() * indexes.length)]; // pick new index
        }

    },

    beforeDestroy() {
        clearInterval(this.clientsInterval);
        this.clientsInterval = null;
    }

};

</script>


<style lang="scss">

</style>
