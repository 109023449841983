<template>

    <transition name="fade" mode="out-in">
        <client-grid></client-grid>
    </transition>

</template>


<script>

import ClientGrid from './components/ClientGrid';

export default {

    name: 'App',

    components: {
        ClientGrid
    },

    data() {
        return {

        };
    },

    mounted() {

    }
};

</script>


<style lang="scss">
#app {
    font-family: $base-default;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}
</style>
