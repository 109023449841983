import Vue from 'vue';
import App from './App.vue';
import store from './store';
import EventBusPlugin from './components/utilities/EventBusPlugin';

Vue.use(EventBusPlugin);

Vue.config.productionTip = false;

// global app/component styles
require('./scss/main.scss');

const mountEl = document.querySelector('#neo-client-grid');

new Vue({
    // Get mount point HTML element attributes & setup w/props
    // Note: Values coming in will be type string, take care & cast/convert as required.
    propsData: { ...mountEl.dataset },
    props: {
        limit: {
            type: String,
            default: '4'
        },
        timeout: {
            type: String,
            default: '3000'
        }
    },
    store,
    render: h => h(App)
}).$mount('#neo-client-grid');
